$(function() {
	$(window).load(function(){
		$('ul.rslides li').clequalize('height');
		
		$('div.download-grid').masonry({
			itemSelector : '.col-md-4'
		});
		$('.gallery').masonry({
			itemSelector : '.col-sm-4'
		});
		
		// ==================================================
		// gallery hover
		// ==================================================
		$('.gallery .item').hover(function() {
			$('.gallery .item').not($(this)).stop().animate({opacity: .3}, 100);
		}, function() {
			$('.gallery .item').stop().animate({opacity: 1});
		}, 100);
	});
	
	
	var maxtweets = 4;
	$(".twitter-container").each(function() {
		var twitterUrl = $(this).data("url");
		if( twitterUrl ) {
			var $tweets = $(this);
			$tweets.twitter({ 
				updateInterval: 120, 
				timelineUrl: twitterUrl,
				timelineData: { q: 'from:' },
				onupdate: function() {
					if ($tweets.children(":not('.loading')").length === 0) {
						$tweets.prepend("<article class='item no-tweets'><div class='body text'>No recent tweets.</div></article>");
						$tweets.children(".loading").remove();
					}
				},
				onstatuses: function(statuses) {
					$tweets.children(".loading, .no-tweets").remove();
					$tweets.children(".item:gt(" + (maxtweets - 1) + ")").remove();
					$tweets.children(".item:odd").removeClass("even").addClass("odd");
					$tweets.children(".item:even").removeClass("odd").addClass("even");
					$(".latest-tweets").show();
				}
			});
		}
	});

	$(".errata").columnize({ 
		width: 300
	});
})

function contactFormSubmit() {
	$("#contact-form").submit();
}

function submissionFormSubmit() {
	$("#submission-form").submit();
}
