jQuery(document).ready(function() {
	// ==================================================
	// change menu on mobile version
	// ==================================================
	domready(function(){
		selectnav('mainmenu', {
			label: 'Menu',
			nested: true,
			indent: '-'
		});
	});


	// ==================================================
	// paralax background
	// ==================================================
	$window = jQuery(window);
   	jQuery('section[data-type="background"]').each(function(){
    var $bgobj = jQuery(this); // assigning the object

    jQuery(window).scroll(function() {
	var yPos = -($window.scrollTop() / $bgobj.data('speed'));
	var coords = '50% '+ yPos + 'px';
	$bgobj.css({ backgroundPosition: coords });

	});
 	});
	document.createElement("article");
	document.createElement("section");


	// ==================================================
	// static header on scroll
	// ==================================================
	jQuery(window).scroll(function(){
	  if (jQuery(this).scrollTop() > 0){
		jQuery('body').addClass("att2");
		jQuery('header').addClass("att1");

	  }
	  else{
		// ==================================================
		//back to default styles
		// ==================================================
		jQuery('body').removeClass("att2");
		jQuery('header').removeClass("att1");
	  }
	});


	// ==================================================
	// scroll to top
	// ==================================================
	jQuery().UItoTop({ easingType: 'easeOutQuart' });


	// ==================================================
	// lazyload
	// ==================================================
	 $(function() {
          $("img").lazyload({
              effect : "fadeIn",
			  effectspeed: 900

          });
      });
});
