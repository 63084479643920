/* 

Mike's hacked version of Karl's awesome plugin. Sorry Karl.
Edited version created July 2011.

The plugin now allows you to pass in a 'step' value.
This will break items into groups of x, where x is the step value, and equalize 
each group individually ��� very usefull for rows of items where there is a fixed 
number of items per row.

e.g. $('ul li').equalize('height', 4)

Note: you can pass in the step value using the "data-eqcols" attribute on the
parent, and it will override any step value passed in when the plugin is called.

*/
jQuery.fn.clequalize = function(attr, step) {
	var maxValue = 0;
	var itemCount = 0;
	var $items = $(this);
	
	var cleq = {
		init : function(){
			// if cols passed through in data attribute, use instead
			var dataStep = $items.parent().data('eqcols');
			if( dataStep != null ){
				step = dataStep;
			}
			if( step != null ){
				if( $items.length > step ){
					cleq.step();
				} else {
					cleq.adjust();
				}
			} else {
				cleq.adjust();
			}			
		},
		step : function(){
			var upper = step + itemCount,
				$itemSet;

			if( itemCount == 0 ){
				$itemSet = $items.filter(':lt(' + step + ')');
			} else {
				$itemSet = $items.filter(':gt(' + (itemCount - 1) + ')');
				$itemSet = $itemSet.filter(':lt(' + step + ')');
			}

			cleq.setmax($itemSet);
			$itemSet.css(''+attr+'', maxValue);

			maxValue = 0;

			if( itemCount < $items.length - step ){
				cleq.step();
			} else {
				// balance for remaining items
				$itemSet = $items.filter(':gt(' + (itemCount - 1) + ')');
				cleq.setmax($itemSet);
				$itemSet.css(''+attr+'', maxValue);
			}
			
		},
		setmax : function(ob){
			$(ob).each(function(){
				var value = cleq.value(this, attr);
				if (value > maxValue){
					maxValue = value;
				}
				itemCount++;
			});
		},
		adjust : function(){
			$items.each(function() {
				var value = cleq.value(this, attr);
				if (value > maxValue){
					maxValue = value;
				}
			});

			$items.css(''+attr+'', maxValue);
		},
		value : function(ob){
			if (attr == "height"){
				return $(ob).height();
			} else if (attr == "width"){
				return $(ob).width();
			}
		}
	};
	
	cleq.init();
};